import { render, staticRenderFns } from "./age.vue?vue&type=template&id=320f1a29&scoped=true&"
import script from "./age.vue?vue&type=script&lang=js&"
export * from "./age.vue?vue&type=script&lang=js&"
import style0 from "./age.vue?vue&type=style&index=0&id=320f1a29&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "320f1a29",
  null
  
)

export default component.exports